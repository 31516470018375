[class^="orejime-"] {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  vertical-align: baseline;
  cursor: default;
  float: none;
  width: auto;
  text-align: left; }

#orejime {
  /*
  .orejime-Notice--mandatory {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      max-width: none;
      transform: none;
  }
  */ }
  #orejime .orejime-Main,
  #orejime .orejime-Notice,
  #orejime .orejime-Modal {
    line-height: 1.5;
    font-size: 12px; }
    @media screen and (min-width: 320px) {
      #orejime .orejime-Main,
      #orejime .orejime-Notice,
      #orejime .orejime-Modal {
        font-size: calc(12px + 2 * (100vw - 320px) / 880); } }
    @media screen and (min-width: 880px) {
      #orejime .orejime-Main,
      #orejime .orejime-Notice,
      #orejime .orejime-Modal {
        font-size: 14px; } }
  #orejime .orejime-Button {
    border: 0;
    color: #fff;
    border-radius: 4px;
    padding: 0.75em 1.5em;
    margin-right: 0.5em;
    border: 1px solid transparent;
    font-weight: normal;
    font-size: 1.125em;
    line-height: 1.5; }
    #orejime .orejime-Button[disabled] {
      opacity: 0.75; }
  #orejime .orejime-Button--save,
  #orejime .orejime-Button--accept {
    background: #008A28;
    color: #fff;
    transition: all 0.3s ease; }
    #orejime .orejime-Button--save:hover, #orejime .orejime-Button--save:active, #orejime .orejime-Button--save:focus,
    #orejime .orejime-Button--accept:hover,
    #orejime .orejime-Button--accept:active,
    #orejime .orejime-Button--accept:focus {
      background: #005719; }
  #orejime .orejime-Button--decline {
    background-color: transparent;
    color: #767676;
    transition: all 0.3s ease; }
    #orejime .orejime-Button--decline:hover, #orejime .orejime-Button--decline:active, #orejime .orejime-Button--decline:focus {
      color: #5d5d5d; }
  #orejime .orejime-Button--info {
    background-color: #dddddd;
    transition: all 0.3s ease;
    color: #444; }
    #orejime .orejime-Button--info:hover, #orejime .orejime-Button--info:active, #orejime .orejime-Button--info:focus {
      background-color: #c4c4c4; }
  #orejime .orejime-Notice {
    background: #fff;
    position: fixed;
    z-index: 1000;
    bottom: calc(50% - 1rem);
    left: calc(50% - 1rem);
    transform: translate(-50%, 50%);
    margin: 1rem;
    width: 560px;
    max-width: calc(100% - 2rem);
    max-height: calc(100% - 2rem);
    overflow: auto;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.8);
    border-radius: 10px; }
  #orejime .orejime-Notice--hidden {
    display: none !important; }
  #orejime .orejime-Notice-body {
    padding: 1rem; }
    @media screen and (min-width: 720px) {
      #orejime .orejime-Notice-body {
        padding: 1.5rem 2rem;
        /*
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        */ } }
  #orejime .orejime-Notice-logo {
    max-width: 200px; }
  #orejime .orejime-Notice-title,
  #orejime .orejime-Notice-description {
    color: #444;
    margin-bottom: 1rem; }
  #orejime .orejime-Notice-title {
    font-weight: 300;
    font-size: 1.75em;
    line-height: 1.3; }
  #orejime .orejime-Notice-purposes {
    color: #444; }
  #orejime .orejime-Notice-changes {
    color: #008A28;
    margin-bottom: 0.5em;
    font-weight: bold; }
  #orejime .orejime-Notice-actions-wrapper {
    /*
    @media screen and (min-width: 720px) {
      margin-left: 2rem;
      width: calc(38.2% - 2rem);
      flex: 1 0 auto;
      justify-content: flex-end;
      display: flex;
      flex-direction: column;
    }
    
    @media screen and (min-width: 960px) {
      width: 25%;
    }
    */ }
    @media screen and (min-width: 480px) {
      #orejime .orejime-Notice-actions-wrapper {
        width: 100%;
        flex: 1 0 auto;
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap; } }
  #orejime .orejime-Notice-actions {
    /*
    @media screen and (min-width: 720px) {
      justify-content: flex-end;
      flex-direction: column;
    }
    */ }
    @media screen and (min-width: 480px) {
      #orejime .orejime-Notice-actions {
        width: 100%;
        flex: 1 0 auto;
        justify-content: space-between;
        display: flex;
        flex-direction: row; } }
    #orejime .orejime-Notice-actions .orejime-Button {
      width: 100%;
      text-align: center; }
  #orejime .orejime-Notice-actionItem {
    display: block;
    margin-bottom: 1em;
    width: 100%;
    /*
    @media screen and (min-width: 720px) {
      display: block;
      width: 100%;
      flex: none;
    }
    */ }
    @media screen and (min-width: 480px) {
      #orejime .orejime-Notice-actionItem {
        width: calc(50% - 1rem); } }
  #orejime .orejime-Notice-footer {
    display: flex;
    justify-content: center;
    width: 100%; }
  #orejime .orejime-Notice-footerItem {
    list-style: none;
    border-right: 1px solid #767676;
    line-height: 1;
    padding: 0 0.5em; }
    #orejime .orejime-Notice-footerItem:last-child {
      border-right: none; }
  #orejime .orejime-Notice-privacyPolicyLink,
  #orejime .orejime-Notice-imprintLink {
    text-decoration: underline;
    cursor: pointer;
    color: #767676; }
    #orejime .orejime-Notice-privacyPolicyLink:hover,
    #orejime .orejime-Notice-imprintLink:hover {
      color: inherit; }
    #orejime .orejime-Notice-privacyPolicyLink:hover, #orejime .orejime-Notice-privacyPolicyLink:focus,
    #orejime .orejime-Notice-imprintLink:hover,
    #orejime .orejime-Notice-imprintLink:focus {
      text-decoration: none; }
  #orejime .orejimeHtml-WithModalOpen {
    height: 100%; }
  #orejime .orejimeHtml-WithModalOpen body {
    position: fixed;
    overflow: hidden;
    height: 100%;
    width: 100%; }
  #orejime .orejime-ModalOverlay,
  #orejime .orejime-NoticeOverlay {
    z-index: 1000;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  #orejime .orejime-ModalWrapper {
    z-index: 1001;
    position: fixed;
    width: 640px;
    bottom: calc(50% - 1rem);
    left: calc(50% - 1rem);
    transform: translate(-50%, 50%);
    margin: 1rem;
    max-width: calc(100% - 2rem);
    max-height: calc(100% - 2rem);
    overflow: auto;
    outline: none;
    background: #fff;
    color: #444;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px; }
  #orejime .orejime-Modal-header {
    padding: 1em;
    border-bottom: 1px solid #999; }
    @media screen and (min-width: 720px) {
      #orejime .orejime-Modal-header {
        padding: 1.5rem 2rem; } }
  #orejime .orejime-Modal-title {
    font-size: 1.75em;
    display: block;
    font-weight: 300;
    padding-right: 1em;
    color: #444;
    margin-bottom: 1rem; }
  #orejime .orejime-Modal-closeButton {
    border: none;
    background: none;
    color: inherit;
    position: absolute;
    top: 1em;
    right: 1em; }
  #orejime .orejime-CloseIcon {
    stroke: currentColor;
    width: 18px; }
  #orejime .orejime-Modal-body {
    padding: 1em; }
    @media screen and (min-width: 720px) {
      #orejime .orejime-Modal-body {
        padding: 1.5rem 2rem; } }
  #orejime .orejime-Modal-footer {
    padding: 1em;
    border-top: 1px solid #999;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media screen and (min-width: 720px) {
      #orejime .orejime-Modal-footer {
        padding: 1.5rem 2rem; } }
    #orejime .orejime-Modal-footer .orejime-Notice-footer {
      align-items: flex-end;
      width: auto;
      flex-direction: column; }
      #orejime .orejime-Modal-footer .orejime-Notice-footer .orejime-Notice-footerItem {
        border-right: none;
        line-height: 1.5;
        padding: 0; }
  #orejime .orejime-Modal-privacyPolicyLink,
  #orejime .orejime-Modal-poweredByLink {
    cursor: pointer;
    text-decoration: underline;
    color: inherit; }
    #orejime .orejime-Modal-privacyPolicyLink:hover, #orejime .orejime-Modal-privacyPolicyLink:focus, #orejime .orejime-Modal-privacyPolicyLink:active,
    #orejime .orejime-Modal-poweredByLink:hover,
    #orejime .orejime-Modal-poweredByLink:focus,
    #orejime .orejime-Modal-poweredByLink:active {
      color: inherit; }
    #orejime .orejime-Modal-privacyPolicyLink:hover, #orejime .orejime-Modal-privacyPolicyLink:focus,
    #orejime .orejime-Modal-poweredByLink:hover,
    #orejime .orejime-Modal-poweredByLink:focus {
      text-decoration: none; }
  #orejime .orejime-Modal-poweredByLink {
    position: absolute;
    right: 1em;
    color: #767676;
    font-size: 0.8em;
    padding: 6px 0; }
    #orejime .orejime-Modal-poweredByLink:hover, #orejime .orejime-Modal-poweredByLink:focus, #orejime .orejime-Modal-poweredByLink:active {
      color: #767676; }
  #orejime .orejime-AppList {
    display: block;
    padding: 0;
    margin: 0;
    max-height: 40vh;
    overflow: auto; }
  #orejime .orejime-AppList-item {
    display: block;
    position: relative;
    line-height: 1.25;
    vertical-align: middle;
    padding-left: 65px;
    min-height: 40px;
    margin-top: 0.7em; }
    #orejime .orejime-AppList-item:first-child {
      margin-top: 0; }
  #orejime .orejime-AppItem-title {
    font-weight: 600;
    cursor: pointer;
    font-size: 1.125em; }
  #orejime .orejime-AppItem-purposes {
    font-size: 0.8em;
    color: #767676; }
  #orejime .orejime-AppItem-optOut,
  #orejime .orejime-AppItem-required {
    padding-left: 0.2em;
    font-size: 0.8em;
    color: #767676; }
  #orejime .orejime-AppItem-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 50px;
    height: 30px; }
  #orejime .orejime-AppItem-switch {
    position: absolute;
    left: 0;
    display: inline-block;
    width: 50px;
    height: 30px;
    margin-left: 6px;
    font-size: 0.8em; }
  #orejime .orejime-AppItem-slider {
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #aaa;
    -webkit-transition: .4s;
    transition: .4s;
    width: 50px;
    display: inline-block;
    border-radius: 30px;
    border: 2px solid transparent; }
    #orejime .orejime-AppItem-slider:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
      border: 1px solid #767676; }
  #orejime .orejime-AppItem-switchLabel {
    position: absolute;
    width: 50px;
    top: 34px;
    overflow: ellipsis;
    text-align: center; }
  #orejime .orejime-AppItem-switch--disabled .orejime-AppItem-slider {
    cursor: default;
    opacity: 0.5; }
  #orejime .orejime-AppItem-switch--disabled .orejime-AppItem-switchLabel {
    color: #aaa; }
  #orejime .orejime-AppItem-input:focus + .orejime-AppItem-label .orejime-AppItem-slider {
    border-color: #fff;
    outline-offset: 2px; }
  #orejime .orejime-AppItem-input:checked + .orejime-AppItem-label .orejime-AppItem-slider {
    background-color: #008A28; }
  #orejime .orejime-AppItem-input:checked + .orejime-AppItem-label .orejime-AppItem-slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px); }
  #orejime .orejime-AppToggles {
    margin-bottom: 1em;
    display: block; }
  #orejime .orejime-AppToggles-item {
    display: inline; }
  #orejime .orejime-AppItem-label {
    position: static;
    padding-left: 0;
    display: block;
    font-size: inherit;
    outline: none;
    height: 30px;
    line-height: 30px; }
  #orejime .orejime-AppItem-label:before {
    content: none; }
  #orejime .orejime-AppItem-label:after {
    content: none; }
  #orejime .orejime-Modal-privacyPolicyLink:hover,
  #orejime .orejime-Modal-privacyPolicyLink:focus,
  #orejime .orejime-Modal-poweredByLink:hover,
  #orejime .orejime-Modal-poweredByLink:focus {
    text-decoration: none;
    background: transparent; }
