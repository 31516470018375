$orejime-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19) !default;
$orejime-border-radius: 4px !default;

$orejime-blue: #057EB6 !default;
$orejime-green: #008A28 !default;
$orejime-gray: #dddddd !default;

$orejime-theme-bg: #fff !default;
$orejime-theme-border: 1px solid #999 !default;
$orejime-theme-color: #444 !default;
$orejime-theme-color-light: #767676 !default;

// micro css reset for everything orejime related
[class^="orejime-"] {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  vertical-align: baseline;
  cursor: default;
  float: none;
  width: auto;
  text-align: left;
}

#orejime {
  .orejime-Main,
  .orejime-Notice,
  .orejime-Modal {
    line-height: 1.5;
    font-size: 12px;
    @media screen and (min-width: 320px) {
      font-size: calc(12px + 2 * (100vw - 320px) / 880);
    }
    @media screen and (min-width: 880px) {
      font-size: 14px;
    }
  }

  .orejime-Button {
    border: 0;
    color: #fff;
    border-radius: $orejime-border-radius;
    padding: 0.75em 1.5em;
    margin-right: 0.5em;
    border: 1px solid transparent;
    font-weight: normal;
    font-size: 1.125em;
    line-height: 1.5;
    &[disabled] {
      opacity: 0.75;
    }
  }

  .orejime-Button--save,
  .orejime-Button--accept {
    background: $orejime-green;
    color: #fff;
    transition: all 0.3s ease;
    &:hover,
    &:active,
    &:focus {
      background: darken($orejime-green, 10%);
    }
  }

  .orejime-Button--decline {
    background-color: transparent;
    color: $orejime-theme-color-light;
    transition: all 0.3s ease;
    &:hover,
    &:active,
    &:focus {
      color: darken($orejime-theme-color-light, 10%);
    }
  }

  .orejime-Button--info {
    background-color: $orejime-gray;
    transition: all 0.3s ease;
    color: $orejime-theme-color;
    &:hover,
    &:active,
    &:focus {
      background-color: darken($orejime-gray, 10%);
    }
  }

  .orejime-Notice {
    background: $orejime-theme-bg;
    position: fixed;
    z-index: 1000;
    bottom: calc(50% - 1rem);
    left: calc(50% - 1rem);
    transform: translate(-50%, 50%);
    margin: 1rem;
    width: 560px;
    max-width: calc(100% - 2rem);
    max-height: calc(100% - 2rem);
    overflow: auto;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.8);
    border-radius: 10px;
  }
  /*
  .orejime-Notice--mandatory {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      max-width: none;
      transform: none;
  }
  */

  .orejime-Notice--hidden {
      display: none !important;
  }

  .orejime-Notice-body {
      padding: 1rem;
      
      @media screen and (min-width: 720px) {
        padding: 1.5rem 2rem;
        /*
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        */
      }
  }

  .orejime-Notice-logo {
      max-width: 200px;
  }

  .orejime-Notice-title,
  .orejime-Notice-description {
      color: $orejime-theme-color;
      margin-bottom: 1rem;
  }

  .orejime-Notice-title {
      font-weight: 300;
      font-size: 1.75em;
      line-height: 1.3;
  }

  .orejime-Notice-purposes {
      color: $orejime-theme-color;
  }

  .orejime-Notice-changes {
      color: $orejime-green;
      margin-bottom: 0.5em;
      font-weight: bold;
  }

  .orejime-Notice-actions-wrapper {
    @media screen and (min-width: 480px) {
      width: 100%;
      flex: 1 0 auto;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    /*
    @media screen and (min-width: 720px) {
      margin-left: 2rem;
      width: calc(38.2% - 2rem);
      flex: 1 0 auto;
      justify-content: flex-end;
      display: flex;
      flex-direction: column;
    }
    
    @media screen and (min-width: 960px) {
      width: 25%;
    }
    */
  }

  .orejime-Notice-actions {
    @media screen and (min-width: 480px) {
      width: 100%;
      flex: 1 0 auto;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
    }
    /*
    @media screen and (min-width: 720px) {
      justify-content: flex-end;
      flex-direction: column;
    }
    */
    .orejime-Button {
      width: 100%;
      text-align: center;
    }
  }

  .orejime-Notice-actionItem {
    display: block;
    margin-bottom: 1em;
    width: 100%;
    @media screen and (min-width: 480px) {
      width: calc(50% - 1rem);
    }
    /*
    @media screen and (min-width: 720px) {
      display: block;
      width: 100%;
      flex: none;
    }
    */
  }

  .orejime-Notice-footer {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .orejime-Notice-footerItem {
    list-style: none;
    border-right: 1px solid $orejime-theme-color-light;
    line-height: 1;
    padding: 0 0.5em;
    &:last-child {
      border-right: none;
    }
  }

  .orejime-Notice-privacyPolicyLink,
  .orejime-Notice-imprintLink {
      text-decoration: underline;
      cursor: pointer;
      color: $orejime-theme-color-light;
      &:hover {
          color: inherit;
      }
      &:hover,
      &:focus {
          text-decoration: none;
      }
  }

  .orejimeHtml-WithModalOpen {
      height: 100%;
  }

  .orejimeHtml-WithModalOpen body {
      position: fixed;
      overflow: hidden;
      height: 100%;
      width: 100%;
  }

  .orejime-ModalOverlay,
  .orejime-NoticeOverlay {
      z-index: 1000;
      background: rgba(0,0,0,0.5);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
  }

  .orejime-ModalWrapper {
      z-index: 1001;
      position: fixed;
      width: 640px;
      bottom: calc(50% - 1rem);
      left: calc(50% - 1rem);
      transform: translate(-50%, 50%);
      margin: 1rem;
      max-width: calc(100% - 2rem);
      max-height: calc(100% - 2rem);
      overflow: auto;
      outline: none;
      background: $orejime-theme-bg;
      color: $orejime-theme-color;
      box-shadow: $orejime-box-shadow;
      border-radius: 10px;
  }

  .orejime-Modal-header {
      padding: 1em;
      border-bottom: $orejime-theme-border;
      @media screen and (min-width: 720px) {
        padding: 1.5rem 2rem;
      }
  }

  .orejime-Modal-title {
      font-size: 1.75em;
      display: block;
      font-weight: 300;
      padding-right: 1em;
      color: $orejime-theme-color;
      margin-bottom: 1rem;
  }

  .orejime-Modal-closeButton {
      border: none;
      background: none;
      color: inherit;
      position: absolute;
      top: 1em;
      right: 1em;
  }

  .orejime-CloseIcon {
      stroke: currentColor;
      width: 18px;
  }

  .orejime-Modal-body {
      padding: 1em;
      @media screen and (min-width: 720px) {
        padding: 1.5rem 2rem;
      }
  }

  .orejime-Modal-footer {
      padding: 1em;
      border-top: $orejime-theme-border;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @media screen and (min-width: 720px) {
        padding: 1.5rem 2rem;
      }
      
      .orejime-Notice-footer {
        align-items: flex-end;
        width: auto;
        flex-direction: column;
        .orejime-Notice-footerItem {
          border-right: none;
          line-height: 1.5;
          padding: 0;
        }
      }
  }

  .orejime-Modal-privacyPolicyLink,
  .orejime-Modal-poweredByLink {
      cursor: pointer;
      text-decoration: underline;
      color: inherit;

      &:hover,
      &:focus,
      &:active {
          color: inherit;
      }

      &:hover,
      &:focus {
          text-decoration: none;
      }
  }

  .orejime-Modal-poweredByLink {
      position: absolute;
      right: 1em;
      color: $orejime-theme-color-light;
      font-size: 0.8em;
      padding: 6px 0;

      &:hover,
      &:focus,
      &:active {
          color: $orejime-theme-color-light;
      }
  }

  .orejime-AppList {
      display: block;
      padding: 0;
      margin: 0;
      max-height: 40vh;
      overflow: auto;
  }

  .orejime-AppList-item {
      display: block;
      position: relative;
      line-height: 1.25;
      vertical-align: middle;
      padding-left: 65px;
      min-height: 40px;
      margin-top: 0.7em;

      &:first-child {
          margin-top: 0;
      }
  }

  .orejime-AppItem-title {
      font-weight: 600;
      cursor: pointer;
      font-size: 1.125em;
  }

  .orejime-AppItem-purposes {
      font-size: 0.8em;
      color: $orejime-theme-color-light;
  }

  .orejime-AppItem-optOut,
  .orejime-AppItem-required {
      padding-left: 0.2em;
      font-size: 0.8em;
      color: $orejime-theme-color-light;
  }

  .orejime-AppItem-input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 50px;
      height: 30px;
  }

  .orejime-AppItem-switch {
      position: absolute;
      left: 0;
      display: inline-block;
      width: 50px;
      height: 30px;
      margin-left: 6px;
      font-size: 0.8em;
  }

  .orejime-AppItem-slider {
      box-shadow: $orejime-box-shadow;
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #aaa;
      -webkit-transition: .4s;
      transition: .4s;
      width: 50px;
      display: inline-block;
      border-radius: 30px;
      border: 2px solid transparent;

      &:before {
          position: absolute;
          content: "";
          height: 20px;
          width: 20px;
          left: 3px;
          bottom: 3px;
          background-color: white;
          -webkit-transition: .4s;
          transition: .4s;
          border-radius: 50%;
          border: 1px solid $orejime-theme-color-light;
      }
  }

  .orejime-AppItem-switchLabel {
      position: absolute;
      width: 50px;
      top: 34px;
      overflow: ellipsis;
      text-align: center;
  }

  .orejime-AppItem-switch--disabled .orejime-AppItem-slider {
      cursor: default;
      opacity: 0.5;
  }

  .orejime-AppItem-switch--disabled .orejime-AppItem-switchLabel {
      color: #aaa;
  }

  .orejime-AppItem-input:focus + .orejime-AppItem-label .orejime-AppItem-slider {
      border-color: #fff;
      outline-offset: 2px;
  }

  .orejime-AppItem-input:checked + .orejime-AppItem-label .orejime-AppItem-slider {
      background-color: $orejime-green;
  }

  .orejime-AppItem-input:checked + .orejime-AppItem-label .orejime-AppItem-slider:before {
      -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
      transform: translateX(20px);
  }

  .orejime-AppToggles {
      margin-bottom: 1em;
      display: block;
  }

  .orejime-AppToggles-item {
      display: inline;
  }

  .orejime-AppItem-label {
    position: static;
    padding-left: 0;
    display: block;
    font-size: inherit;
    outline: none;
    height: 30px;
    line-height: 30px;
  }
  .orejime-AppItem-label:before {
    content: none;
  }
  .orejime-AppItem-label:after {
    content: none;
  }
  .orejime-Modal-privacyPolicyLink:hover,
  .orejime-Modal-privacyPolicyLink:focus,
  .orejime-Modal-poweredByLink:hover,
  .orejime-Modal-poweredByLink:focus {
    text-decoration: none;
    background: transparent;
  }



}

